/* GoogleSignIn COMPONENT */
.Signin-content {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-signin {
  width: 380px;
  height: 480px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-sizing: content-box;
  overflow: hidden;
}

.card-signin .top {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px;
  background: rgb(111, 43, 255);
  background: linear-gradient(
    0deg,
    rgba(111, 43, 255, 1) 0%,
    rgba(26, 90, 255, 1) 100%
  );
}

.card-signin .top .title {
  color: #ffffff;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
}

.card-signin .bottom {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}

.card-signin .bottom .welcome {
  color: #5c5c5c;
  font-size: 14px;
}

.card-signin .bottom .separator {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  margin: 15px 0;
}

.card-signin .bottom .btn-signin {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 16px;
  outline: none;
  border: none;
  background-color: #f7f7f7;
}

.card-signin .bottom .btn-signin:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.card-signin .bottom .btn-signin .btn-text {
  color: #2d2d2d;
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
}
/* GoogleSignIn COMPONENT */

/* Home COMPONENT */
.Home-content {
  width: 100%;
  height: 100vh;
}

.Home-content .main-header {
  width: 100%;
  height: 64px;
}

.Home-content .main {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: auto;
}

.Home-content .main .shadow {
  background: rgba(20, 20, 20, 0.705);
  width: 100%;
  height: calc(100vh - 64px);
  display: block;
  position: absolute;
  z-index: 500;
}

.Home-content .title {
  font-size: 24px;
  font-weight: 600;
  color: #141414;
  margin-top: 20px;
}

.Home-content .btn-new-document {
  background: #1a5aff;
  width: 60px;
  height: 60px;
  padding: 10px 20px;
  border-radius: 1000px;
  position: fixed;
  overflow: hidden;
  display: flex;
  z-index: 1000px;

  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  right: 50px;
  bottom: 50px;
  transition: all ease-in 0.2s;
  box-shadow: 0px 7px 91px -39px rgba(111, 43, 255, 1);
}

.Home-content .btn-new-document:hover {
  width: 250px;
}

.Home-content .btn-new-document:hover .text {
  color: #ffffff;
  display: block;
  margin-left: 4px;
  position: relative;
}

.Home-content .btn-new-document .text {
  position: absolute;
  color: transparent;
  transition: all ease-in-out 0.4s;
}

.Home-content .document-list {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 0;
}

.Home-content .document-list .card-document {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.Home-content .document-list .card-document:hover {
  -webkit-box-shadow: 0px 7px 53px -29px rgba(135, 135, 135, 1);
  -moz-box-shadow: 0px 7px 53px -29px rgba(135, 135, 135, 1);
  box-shadow: 0px 7px 53px -29px rgba(135, 135, 135, 1);
}

.Home-content .document-list .card-document .card-text {
  width: 100%;
}

.Home-content .document-list .card-document .card-actions {
  display: flex;
  column-gap: 5px;
}

.Home-content .document-list .card-document .card-actions .btn-action {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.Home-content .document-list .card-document .card-actions .btn-action:hover {
  background-color: #efefef;
  transition: 5ms ease-in-out;
}

.Home-content .document-list .card-document .card-actions .separator {
  border: 0.5px solid #efefef;
}

.Home-content .modal-new-document {
  width: 400px;
  height: auto;
  max-width: 400px;
  border: 2px solid #2d2d2d;
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
}

.Home-content .modal .btn-close {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #f3f3f3;
  padding: 5px 8px;
  border-radius: 10px;
}
.Home-content .modal .btn-close:hover {
  background-color: #d6d6d6;
}

.Home-content .modal-new-document .card-input-upload-file {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home-content .modal-new-document .input-upload-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Home-content .modal-new-document .btn-upload-file {
  margin: 10px 20px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #1a5aff;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
  margin: 10px 0;
}
.Home-content .modal-new-document .btn-upload-file:hover {
  background-color: #0a4ef8;
}

.Home-content .modal-new-document .qr-section {
  width: 100%;
}
.Home-content .modal .btn-qr-download {
  width: 100%;
  outline: none;
  border: 1px solid #1a5aff;
  border-radius: 10px;
  padding: 10px;
  color: #1a5aff;
  font-size: 18px;
}
.Home-content .modal .btn-qr-download:hover {
  background-color: #dfe8ff;
}

.Home-content .modal-qr-viewer {
  position: fixed;
  z-index: 1000;
  width: 450px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
}
.Home-content .modal-qr-viewer .content{
  padding: 20px;
}
.Home-content .modal-qr-viewer .btn-qr-download{
  margin-top: 10px;
}
/* Home COMPONENT */

/*Search COMPONENT*/
.list-content {
  width: 100%;
}
.search-input {
  width: 600px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  outline: none;
  margin-top: 40px;
}

.search-input:focus {
  border-color: #333;
}

.search-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  width: 100%;
  max-width: 300px;
}

.search-item {
  padding: 8px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.search-item:hover {
  background-color: #f5f5f5;
}
/*Search COMPONENT*/

/* PDFViewer COMPONENT */
.PdfViewer-content {
  /* background-color: #f0f0f0; */
  width: 100%;
  height: 100vh;
}
.PdfViewer-content .container {
  padding: 0;
}
.PdfViewer-content .btn-download-pdf {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: calc(100% / 2);
  transform: translateX(50%);
  outline: none;
  padding: 10px;
  border: 1px solid #1a5aff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #1a5aff;
  background-color: #ffffff;
  cursor: pointer;
}
.PdfViewer-content .btn-download-pdf:hover {
  background-color: #dfe8ff;
}
/* PDFViewer COMPONENT */
