* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  width: 100%;
  height: 100vh;
}

.App {
  width: 100%;
  height: 100vh;  
}

.container {
  background: #fff;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: calc(750px - 250px);
  }
}
@media (min-width: 992px) {
  .container {
    width: calc(970px - 250px);
  }
}
@media (min-width: 1200px) {
  .container {
    width: calc(1170px - 250px);
  }
}

/* * {
  font-family: "Inter", sans-serif;
}
html, body, #root, .App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
